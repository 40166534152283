import React from 'react';
import { Translate } from "../Context";

import Logo from "../assets/Images/PENTAX-Medical-Logo.svg";
import VideoPosterImage from "../assets/Images/embed-video-poster.png";

// Styles
const VideoPoster = {
    backgroundImage: `url(${VideoPosterImage})`,
}

function HeaderVideo(props) {
  // const {  } = props;

  return (
      <section className={"video-container"}>
          <div className={"video-logo container no-spacers"}>
              <img src={Logo} title={"PENTAX Medical"} alt={"PENTAX Medical"}/>
          </div>
          <div className="embed-video">
              <div className={"embed-video-poster"} style={VideoPoster}/>
              <iframe
                  frameBorder="0"
                  height="1080"
                  width="1920"
                  src="https://player.vimeo.com/video/698228879?h=c6649c1be8&autoplay=1&background=1&muted=1&loop=0&autopause=0&app_id=58479"
                  title={Translate('videoIframeTitle')}
              />
          </div>
      </section>
  );
}

export default HeaderVideo;
