import React, { useEffect } from 'react';
import textInScreen from '../assets/JavaScripts/text-animation';
import {v4 as uuidv4} from "uuid";

function ScrollTextAnimation(props) {
  const { text } = props;
    const uuid = uuidv4();

  useEffect(() => {
      textInScreen(document.getElementById(uuid));
  }, [uuid])

  return (
      <div className={"text-animation"} data-text={text} id={uuid}>{text}</div>
  );
}

export default ScrollTextAnimation;
