export function debounce(func, debounceTime = 100) {
    let timer;
    return function(event){
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, debounceTime, event);
    };
}

export function isAnchorOnSamePage(anchorHref) {
    const
        winLoc = window.location,
        anchorLoc = (anchorHref && !anchorHref.startsWith('#')) ? new URL(anchorHref, window.location.origin) : false,
        anchorStartsWith = anchorHref && anchorHref.startsWith('#')
    ;
    return winLoc.pathname === anchorLoc.pathname || anchorStartsWith;
}

export function getOffsetTop(element) {
    let offsetTop = 0;
    while(element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
}

export function isMobile() {
    return (window.innerWidth <= 992);
}
