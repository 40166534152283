import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/scss/index.scss';

ReactDOM.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
, document.getElementById('content'));

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
// import './assets/scss/index.scss';
//
// ReactDOM.createRoot(document.getElementById('content')).render(
//     // <React.StrictMode>
//         <App />
//     // </React.StrictMode>
// );
