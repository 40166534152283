import { Fragment, useEffect } from "react";
import { Translate, Component } from "./Context";
import { Fade } from "@stahl.luke/react-reveal";

// Components
import HeaderVideo from "./components/HeaderVideo";
import Container from "./components/Container";
import ScrollTextAnimation from "./components/Scroll-Text-Animation";
import RotateMessage from "./components/RotateMessage";
import ParallaxBg from "./components/ParallaxBg";
import Footer from "./components/Footer";
import StickyButton from "./components/StickyButton";

// Images
import Hotel from "./assets/Images/hotel.jpg";
import BgCubesImage from "./assets/Images/bg-cubes.png";

// Styles
const BgCubes = {
  backgroundImage: `url(${BgCubesImage})`,
}

function App() {
    useEffect(() => {
        document.title = Translate('pageTitle');
    }, []);

    return (
        <Fragment>

            <HeaderVideo/>

            <Container className={"bg-grey"}>
                {/*<div className={"col-md-12 m-b-0"}>*/}
                {/*    <ScrollTextAnimation text={"Invitation"}/>*/}
                {/*</div>*/}
                <div className={"col-md-12"}>
                    <Fade bottom duration={2000}><p className={"big m-b-0"}>{Component('eventHeader')}</p></Fade>
                </div>
            </Container>

            <Container className={"bg-transparent-white"}>
                <div className={"col-lg-8 col-xl-6 col-xl-offset-6"}>
                    <ScrollTextAnimation text={"Inspiring"}/>
                    {/*<Fade bottom duration={2000}><p className="big t-shadow">Exclusive preview</p></Fade>*/}
                    <Fade bottom duration={2000}><p className={"t-shadow"}><strong>Exclusive preview:</strong> Be one of the first to experience the innovation and its clinical relevance before the official launch. Learn how it can support you in your daily practice.</p></Fade>
                </div>
            </Container>

            {/*<Container className={"bg-grey"}>*/}
            {/*    <div className={"col-md-12 m-b-0"}>*/}
            {/*        <ScrollTextAnimation text={"International"}/>*/}
            {/*    </div>*/}
            {/*    <div className={"col-md-6"}>*/}
            {/*        <Fade bottom duration={2000}><p className={"big"}>Global experts sharing their know-how</p></Fade>*/}
            {/*    </div>*/}
            {/*    <div className={"col-md-12"}>*/}
            {/*        <div className={"row"}>*/}
            {/*            <div className={"col-md-6"}>*/}
            {/*                <Fade bottom duration={2000}><p>Gastroenterology expert Anne Marie O’Broin Lennon, Ph.D. from Johns Hopkins Outpatient Center, Baltimore, USA, will introduce you to the latest insights from colonoscopy.<br/><br/>Name Lorem Ipsum<br/>Title (placeholder)</p></Fade>*/}
            {/*            </div>*/}
            {/*            <div className={"col-md-6"}>*/}
            {/*                <Fade bottom duration={2000}><img src={Hotel} alt={"Hotel"}/></Fade>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Container>*/}

            <Container className={"bg-transparent-white bg-image"} style={BgCubes}>
                <div className={"col-md-12 m-b-0"}>
                    {/*<ScrollTextAnimation text={"Innovative"}/>*/}
                    <ScrollTextAnimation text={"Insightful"}/>
                </div>
                <div className={"col-lg-8 col-xl-6"}>
                    {/*<Fade bottom duration={2000}><p className={"big"}>A revolutionary endoscope cleaning solution</p></Fade>*/}
                    <Fade bottom duration={2000}><p>Listen to and enjoy the external keynote speaker who provides a different perspective in healthcare. Learn more about future trends and innovations.</p></Fade>
                </div>
            </Container>

            <Container className={"bg-transparent-white"}>
                <div className={"col-lg-8 col-xl-6 col-xl-offset-6"}>
                    {/*<ScrollTextAnimation text={"Intelligent"}/>*/}
                    <ScrollTextAnimation text={"Illuminating"}/>
                    {/*<Fade bottom duration={2000}><p className={"big t-shadow"}>Exclusive Preview:<br/>New approaches to therapeutics</p></Fade>*/}
                    <Fade bottom duration={2000}><p className={"t-shadow"}>Leverage the opportunity to exchange and network with renown colleagues and speakers about challenges, needs and innovations to shape the future of endoscopy together.</p></Fade>
                </div>
            </Container>

            <Container className={"bg-white"}>
                <div className={"col-md-12 m-b-0"}>
                    {/*<ScrollTextAnimation text={"Insights"}/>*/}
                    <ScrollTextAnimation text={"Invitation"}/>
                    {/*<Fade bottom duration={2000}><p className={"big"}>Enjoy two exclusive days in Hamburg</p></Fade>*/}
                </div>
                <div className={"col-md-12"}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <Fade bottom duration={2000}><p>Join us for the Blackbox Innovation Meeting and enjoy two exclusive days in the cosmopolitan city of Hamburg, Germany.</p></Fade>
                            <Fade bottom duration={2000}><p><strong>Seats limited – reserve your seat now!</strong></p></Fade>
                            <Fade bottom duration={2000}><p>As our guest, we will take care of your travel bookings.</p></Fade>
                            <Fade bottom duration={2000}><p>Call your PENTAX Medical representative or send an email now.</p></Fade>
                            <Fade bottom duration={2000}><a href={Translate("reserveNowHref")} className={"btn"}>Reserve your seat now</a></Fade>
                            <Fade bottom duration={2000}><a href={Translate("downloadAgendaHref")} target={"_blank"} rel={"noreferrer"} className={"btn btn-outline"}>Download agenda</a></Fade>
                        </div>
                        <div className={"col-md-6"}>
                            <div className={"spacer-double hideOn-xl hideOn-lg hideOn-xl hideOn-xxl"}/>
                            <Fade bottom duration={2000}><img src={Hotel} alt={"Hotel"}/></Fade>
                        </div>
                    </div>
                </div>
            </Container>

            <Footer/>

            <RotateMessage/>
            <StickyButton/>
            <ParallaxBg/>

        </Fragment>
    );
}

export default App;
