import React from 'react';
import { Translate } from "../Context";

function StickyButton(props) {
  // const { className, children } = props;

  return (
      <a href={Translate('reserveNowHref')} className={"sticky-button"}>
          Reserve your seat now
      </a>
  );
}

export default StickyButton;
