import React, { useEffect, useState } from 'react';
import parallaxBg from "../assets/Images/parallax-bg.jpg";

function ParallaxBg(props) {
    // const { className, children } = props;
    const [bgPosition, setBgPosition] = useState(100);

    useEffect(() => {
        const onScroll = () => {

            const scrollDecimal = (window.scrollY / (document.documentElement.scrollHeight - document.documentElement.clientHeight));
            const scrollOffset = scrollDecimal * 100;
            setBgPosition(100 - scrollOffset);
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <div className={"parallax-bg"} style={{backgroundImage: `url("${parallaxBg}")`, backgroundPosition: `left ${bgPosition}%`}}/>
    );
}

export default ParallaxBg;
