/*
* quick and dirty. sorry.
* */

import { getOffsetTop } from './helpers';

export default function textInScreen(e) {
    if (!e.classList.contains('ta-init')) {
        e.classList.add('ta-init');
        document.addEventListener('scroll', () => {
            const
                thisTop = getOffsetTop(e) - window.scrollY,
                gridTop = window.innerHeight * .1,
                gridBottom = window.innerHeight * .9
            ;
            if (thisTop >= gridTop && (thisTop + e.offsetHeight) <= gridBottom) {
                if (!e.classList.contains('ta-active')) {
                    e.classList.add('ta-active');
                }
            }
        });
    }
}

